
.buttonRounded{
  color: white !important;  
  font-size: 20px !important;
  font-weight: bold !important;
  border-radius: 30px !important;
  min-width: 180px !important;
  text-align: center !important;
  text-transform: none !important;
}
