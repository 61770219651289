@import url(_reset.css);
@import url(_variables.css);
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Pacifico&display=swap');


body {

}
a{
  color: var(--color-orange-mid);
  /* text-decoration: underline; */
}

.MuiFormLabel-root.Mui-focused {
  color: var(--color-orange-mid) !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-orange-mid) !important;
}
.MuiInputBase-root {
  border-radius: 50px !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: var(--color-orange-mid) !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--color-orange-mid) !important;
}