@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Pacifico&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

img {
	width: inherit;
}

button {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	font-weight: inherit;
	padding: 0;
	border: none;
	background-color: unset;
}

input {
	border: none;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	font-family: inherit;
}
:root {
    --fonte-familia-corpo: 'Inter', sans-serif;
    --fonte-familia-logo: 'Inter', cursive;
    --fonte-familia-titulo-pagina: 'Inter', cursive;

    --fonte-peso-input: 300;
    --fonte-peso-cartao: 500;
    --fonte-peso-categorias: 500;
    --fonte-peso-menu-cabecalho-item: 500;

    --fonte-tamanho-logo: 1.75rem;
    --fonte-tamanho-cartao-titulo: 1.75rem;
    --fonte-tamanho-cartao-post-titulo: 1.375rem;
    --fonte-tamanho-input-label: 1.25rem;
    --fonte-tamanho-botao: 1.375rem;
    --fonte-tamanho-formulario-fieldset: 1.375rem;
    --fonte-tamanho-tabela-cabecalho: 1.375rem;
    --fonte-tamanho-titulo-pagina: 1.75rem;
    --fonte-tamanho-menu-cabecalho-item: 1.2rem;
    --fonte-tamanho-naoencontrado-texto: 1.25rem;

    --color-red: #F34213;
    --color-blue: #05C7F2;
    --color-black: #262224;
    --color-white: #ffffff;
    --color-orange: #F27D16;
    --color-orange-light: #F2913D;
    --color-orange-mid: #FF7801;
    --color-blue-dark: #038FAE;
    --color-blue-light: #82E3FB;
    --color-gray: #D9D9D9;
    --color-gray-dark: #797979;


    --color-orange-light-opacity: #F2913D20;
    --sombra: 0 5px 10px #55a6ff38;
}

@media(min-width: 800px) {
    :root {
        --fonte-tamanho-logo: 2.5rem;
        --fonte-tamanho-menu-cabecalho-item: 1rem;
    }
}

body {

}
a{
  color: var(--color-orange-mid);
  /* text-decoration: underline; */
}

.MuiFormLabel-root.Mui-focused {
  color: var(--color-orange-mid) !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-orange-mid) !important;
}
.MuiInputBase-root {
  border-radius: 50px !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: var(--color-orange-mid) !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--color-orange-mid) !important;
}

.buttonRounded{
  color: white !important;  
  font-size: 20px !important;
  font-weight: bold !important;
  border-radius: 30px !important;
  min-width: 180px !important;
  text-align: center !important;
  text-transform: none !important;
}

.dialog{
  width: 100%;
}
.mainHome {
  overflow: hidden;

}

.logoHome {
  width: 250px;
  padding: 20px
}

.logoPixHome {
  width: 200px;
  padding: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
}
  
.backgroundHome {
  bottom: 0;
  position: absolute;
  max-height: calc(100vh - 130px);
  width: 100%;
}

.leftsideHome {
  min-height: 100vh;
}

.rightsideHome {
  background: var(--color-blue);
  min-height: 100vh;
  position: relative;
  background-color: black;
}

.mainDataHome {
  text-align: center;
}

@media(max-width: 800px) {
  .rightsideHome {
    display: none!important;
  }
  .leftsideHome {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

.esqueciMinhaSenha{
  text-align: right;
  padding-right: 10px;
  color: var(--color-orange-mid);
  cursor: pointer;
}
.contentDoubleDiv{
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  width: 60%;
}

.thirdDoubleDiv{
  
  border: 2px solid var(--color-blue-dark) !important;
  border-radius: 30px !important;
  z-index: 0 !important;
  position: relative !important;
  width: 100%;
  left: 10px !important;
}

.secondDoubleDiv{
  border: 2px solid var(--color-blue-light) !important;
  border-radius: 30px !important;
  z-index: 1 !important;
  top: 15px !important;
  position: relative;
  width: 100%;
  left: -15px !important
}

.firstDoubleDiv{
  border: 2px solid var(--color-blue) !important;
  border-radius: 30px !important;
  z-index: 2 !important;
  top: -7px !important;
  left: 5px !important;
  position: relative;
  background-color: white;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
